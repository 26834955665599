export default {
  pageHeroV1: {
    container: {
      height: '75vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['2.5rem', '', '4.5rem'],
        marginBottom: 1,
        letterSpacing: '3px',
        padding: '1rem 1.5rem',
        fontFamily: 'body',
        fontWeight: 'normal',
        color: 'light',
        textTransform: 'uppercase',
        fontFamily: 'heading',
        backgroundColor: '#0a3452a8',
        borderColor: 'solid 3px white'
      },
      h2: {
        marginBottom: 4
      }
    }
  }
}
