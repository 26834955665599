export default {
  text: '#0c3450',
  primary: '#0a3452',
  secondary: '#6d3842',
  brandGrey: '#566464',
  background: 'white',
  backgroundSecondary: '#202020',
  light: '#FFF',
  dark: '#020303'
}
