export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      padding: '0rem',
      height: 'unset'
    },
    '.logo': {
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['90px', '90px', '90px', '90px', '90px']
      }
    },
    '.logoScrolled': {
      display: 'flex',
      height: 'auto',
      maxHeight: 'unset',

      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['50px', '50px', '50px', '50px', '50px']
      }
    },

    '.container': {
      padding: '0.5rem',
      backgroundColor: 'background'
    },
    '.containerScrolled': {
      backgroundColor: 'background',

      padding: ['1rem', '', '0.5rem'],
      height: ['auto']
    },

    '.smallNavMenu': {
      justifyContent: 'center',
      padding: '0rem 2rem',
      '> div': {
        border: 'none'
      },
      '.navItem': {
        a: {
          color: 'text',
          fontSize: '1rem',
          fontFamily: 'body',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          ':hover': {
            textDecoration: 'underline',
            color: 'grey'
          }
        }
      }
    },

    '.hamburger': {
      borderRadius: '10px',
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'text'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'text',
      '> div': {
        backgroundColor: 'text'
      }
    },

    '.navMenuLogo': {
      display: 'none'
    },

    // '.phoneContainer': {
    //   display: 'flex',
    //   border: 'none',
    //   margin: '0rem 1rem',
    //   justifyContent: 'flex-end',
    //   alignItems: 'flex-end',
    //   a: {
    //     backgroundColor: 'primary',
    //     padding: '0.5rem',
    //     color: 'light',
    //     textAlign: 'center',
    //     margin: '0.5rem 0rem',
    //   },
    // },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    }
  },

  footer: {
    backgroundColor: 'background',
    borderTop: '1px solid',

    '.image': {},
    '.multiButtonContainer': {},
    '.contactDetails-container': {
      // display: 'none',
      color: 'white'
    },
    '.copyright': {
      fontSize: '0.8rem'
    },
    '.socialIconsContainer': {},
    '.gonationLogo': {
      a: {
        justifyContent: 'flex-start'
      },
      svg: { maxWidth: '125px' }
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    order: '1',
    textTransform: 'uppercase',
    color: 'secondary'
  },
  subtitle: {
    width: 'fit-content',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.1rem', '1.2rem', '1.5rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontFamily: 'heading',
    color: 'primary',
    fontWeight: 'bold'
  },
  text: {
    lineHeight: '1.75',
    order: '3',
    fontSize: '1.2rem',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'inherit'
    }
  },

  sideBySide1: {
    padding: '0rem',
    margin: '2rem 5%',
    height: ['', '', '80vh'],
    alignItems: ['', '', 'center'],
    '.lazyload-wrapper': {
      justifyContent: 'flex-end',
      height: '100%'
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      marginRight: ['', '', '2rem'],
      borderTop: 'solid 12px',
      borderBottom: 'solid 12px',
      borderColor: 'secondary'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text',
      mb: '1.5rem'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      marginLeft: ['', '', '2rem'],
      borderTop: 'solid 12px',
      borderBottom: 'solid 12px',
      borderColor: 'secondary'
    }
  },

  homepageBlock: {
    marginTop: '1rem',
    textAlign: 'center',
    minHeight: '75vh',
    '.section': {
      margin: 'auto',
      padding: '0.5rem',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: ['', '', '', '55%']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'inherit',
      color: 'inherit'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'inherit',
      color: 'inherit'
    },
    '.text': {
      variant: 'customVariants.text',
      '*': {
        color: 'inherit',
        textAlign: 'inherit'
      },
      color: 'inherit',
      textAlign: 'inherit',
      mb: '1.5rem'
    },
    '.linksContainer': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sliderPageHero: {
    height: '80vh',
    '.slick-slider, .slick-list, .slick-slide, .slick-slide > div, .slick-slide img': {
      height: '80vh'
    },

    '.hero_content_container': {
      left: '50%',
      transform: 'translate(-50%,-50%)',
      color: 'white',
      margin: '0rem',
      '.title': {
        variant: 'customVariants.title',
        marginBottom: '0rem'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    position: 'relative',
    height: '85vh',
    '.slick-slider': {
      height: '85vh',

      '.slick-slide': {
        height: '85vh',
        '>div': {
          height: '85vh'
        },
        img: {
          height: '85vh',
          opacity: '0.85'
        }
      },
      '.slick-arrow': {
        top: 'unset',
        bottom: '0rem',
        backgroundColor: 'primary',
        color: 'white',
        height: '35px',
        width: '35px',
        padding: '0.25rem',
        display: ['none !important', '', 'inline-block !important']
      },
      '.slick-next': {
        right: 'unset',
        left: '3.5rem'
      },
      '.slick-prev': {
        left: '1rem'
      }
    },

    '.hero_content_container': {
      left: '50%',
      transform: 'translate(-50%,-50%)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: 'white',
      maxWidth: 'unset',
      width: '95%',
      margin: '0rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],

      color: 'secondary',
      mb: '0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '2rem', '2.5rem', '2.5rem', '3rem'],
      color: 'primary'
    },
    '.text': {
      variant: 'customVariants.text',
      fontSize: ['1.1rem', '1.2rem', '1.4rem', '1.5rem', '1.5rem'],
      maxWidth: '700px',
      textAlign: 'center',

      '*': {
        color: 'white',
        textAlign: 'center'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageHeroShout: {
    display: 'none !important'
  },

  homepageAbout1: {
    variant: 'customVariants.sideBySide1',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1699382225/sites/anis-table/footer-logo_2.png)',
    backgroundSize: '50%',
    backgroundAttachment: ['', '', 'unset']
  },

  homepageAbout2: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide1'
  },

  // homepageBoxes: {
  //   padding: ['3rem 1rem', '', '', '3rem 2.5rem'],
  //   '.box': {
  //     width: ['100%', '', 'calc(50% - 2rem)', 'calc(33.3% - 3rem)'],
  //     margin: ['', '', '1rem', '1.5rem'],
  //     backgroundColor: 'white',
  //     boxShadow: '0px 0px 8px 10px #f7f7f7',
  //     padding: '1rem',
  //     flexGrow: '1'
  //   },
  //   '.image': {
  //     height: '250px',
  //     marginBottom: '1rem',
  //     objectFit: 'cover'
  //   },
  //   '.title': {
  //     variant: 'customVariants.title',
  //     fontSize: ['1.4rem', '1.5rem', '2rem', '2.25rem', '2.5rem'],
  //     marginBottom: '0.25rem'
  //   },
  //   '.subtitle': {
  //     variant: 'customVariants.subtitle',
  //     fontWeight: '300',
  //     marginBottom: '0.5rem'
  //   },
  //   '.text': {
  //     variant: 'customVariants.text'
  //   },
  //   a: {
  //     order: '5'
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.primary'
  //   }
  // },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.homepageBlock',
    color: 'white'
  },

  homepageNewsLetter: {
    variant: 'customVariants.homepageBlock',
    color: 'text',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1699382225/sites/anis-table/footer-logo_2.png)',
    backgroundSize: '50%',
    backgroundAttachment: ['', '', 'unset']
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem', '', '', '0rem'],
    // margin: ['', '', '', '-7rem 5% 5%'],
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)',
    position: 'relative',
    transform: 'translateY(-50%)',
    flexDirection: 'column',
    width: ['95%', '', '75%'],
    margin: 'auto',

    '.title': {
      backgroundColor: 'white',
      width: ['', '', '80%'],
      padding: ['0.5rem', '', '2rem 2rem 1rem'],
      textTransform: 'Capitalize',
      color: 'text',
      //   width: ['70%'],
      fontFamily: 'heading',
      fontWeight: 'normal',
      borderLeft: '10px solid',
      borderColor: 'primary',
      fontSize: '2rem'
    },
    '.text': {
      fontFamily: 'heading',
      order: ['4', '', '3'],
      backgroundColor: 'white',
      fontWeight: 'normal',
      padding: ['0.5rem', '', '0rem 2rem'],
      color: 'text',
      //   width: ['70%'],
      color: '#4d4d4d',
      fontFamily: 'body',
      borderLeft: '10px solid',
      borderColor: 'primary',
      fontSize: '1.1rem',
      maxWidth: 'unset',
      width: 'auto'
    },
    '.date': {
      backgroundColor: 'white',
      color: 'text',
      fontSize: '0.8rem',
      order: '5',
      padding: ['0.5rem', '', '1rem 2rem 2rem'],
      //   width: ['70%'],
      maxWidth: 'unset',
      borderLeft: '10px solid',
      borderColor: 'primary'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      position: 'absolute',
      left: '70%',
      height: '100%',
      width: '30%',
      order: ['', '', '4'],
      cursor: 'pointer',
      color: 'text',
      img: {
        objectFit: 'cover'
      }
    },
    '.shoutCTABtns': {
      backgroundColor: 'white',
      //   width: ['70%', '70%', '70%', '70%'],
      a: {
        writingMode: 'unset'
      },
      'a:nth-of-type(2)': {
        opacity: '0.8'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  about1: {
    variant: 'customVariants.sideBySide1',
    height: 'unset',
    margin: '0rem',
    padding: '1rem',
    alignItems: ['', '', 'flex-start'],
    flexDirection: ['column', 'column', 'column', 'column'],
    '.lazyload-wrapper': {
      maxWidth: '1100px',
      width: ['100%', '100%', '100%', '100%'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto'
    },
    '.content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '900px',
      margin: ' auto',
      textAlign: 'center',
      position: 'relative',
      top: '-10vh',
      backgroundColor: 'white',
      order: '2',

      width: ['90%', '90%', '90%', '90%', '90%'],
      '.text': {
        fontSize: ['0.8rem', '0.95rem', '0.95rem', '0.95rem', '0.95rem']
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem',
      backgroundColor: 'transparent',
      border: 'none',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      color: 'black'
    },
    '.albumImage': {
      gridRowEnd: 'span 3'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'text'
    },
    backgroundPosition: 'center center',
    color: 'text',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      backgroundColor: 'rgba(255,255,255,0.8)',

      padding: '1rem 0.5rem',
      color: 'black',
      '::placeholder': {
        color: 'black'
      }
    },

    '.submitBtn': {
      variant: 'buttons.primary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '1.5rem', '1.75rem', '2rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Reservations  ====
  // ? ========================

  reservations: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['6rem 1rem', '', '10rem 1rem'],
    flexDirection: 'column',
    backgroundColor: '#f3f3f3',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  // ? ========================
  // ? =======  team   ========
  // ? ========================
  teamMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none'
    }
  },

  teamPage: {},

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    // '.textContent': {
    //   backgroundColor: 'black'
    // },
    // '.imageContainer': {
    //   backgroundColor: '#8a7d74',
    //   img: {
    //     objectFit: 'contain',
    //     padding: '1rem'
    //   }
    // },
    '.boxesHeading': {
      display: 'none'
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  blogPage: {},

  // ? ========================
  // ? =====  services   ======
  // ? ========================

  menu: {
    '.menuSectionDescription': {
      whiteSpace: 'pre'
    }
  },

  servicesMenu: {
    '.menuSectionTitle': {
      display: 'none'
    },
    '.menuSectionDescription': {
      padding: '2rem',
      backgroundColor: 'primary',
      margin: '0rem 0rem 2rem',
      color: 'white',
      whiteSpace: 'pre'
    },
    '.menuItemContainer, .menuItemContainerImgActive': {
      width: ['100%', 'calc(50% - 1rem)', '', 'calc(25% - 1rem)'],
      margin: ['', '0.5rem', '', '0.5rem']
    },
    '.menuItemImageContainer': {
      display: 'block',
      padding: '0.25rem',
      border: 'solid 1px',
      borderColor: 'primary',
      borderRadius: '0px',
      img: {
        padding: '0.25rem'
      }
    },
    '.itemImageFill': {
      paddingBottom: '100%'
    },
    '.menuItemContentContainer': {
      zIndex: '1',
      '::before': {
        content: "''",
        border: 'solid 1px',
        borderColor: 'grey',
        width: 'calc(100% + 0.5rem)',
        height: 'calc(100% + 0.5rem)',
        position: 'absolute',
        left: '-0.25rem',
        top: '-0.25rem',
        zIndex: '-1'
      },
      position: 'relative',
      top: '-3rem',
      padding: '1rem',
      backgroundColor: 'grey',
      width: '90%',
      textAlign: 'center',
      margin: '0rem auto',
      backgroundColor: 'rgba(250, 248, 247, 0.9)',
      padding: '0.5rem'
    },
    '.menuItemName': {
      borderBottom: 'dashed 1px',
      paddingBottom: '0.5rem'
    },

    '.menuItemDescription': {
      fontSize: '0.8rem',
      width: '100%',
      textTransform: 'capitalize'
    }
  },

  buttonsGroup: {
    '.title': {
      display: 'none'
    },
    '.box': {
      alignItems: 'center'
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: ' auto',
      fontWeight: 'bold',
      padding: '4rem 2rem',
      fontWeight: 'normal',
      letterSpacing: '1px',
      fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem'],
      height: '100%',
      textAlign: 'center',
      width: '100%'
    }
  }
}
