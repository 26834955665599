export default {
  heading: 'Tenor Sans, serif',
  subheading: 'Playfair Display',
  body: 'Comfortaa, serif',
  monospace: 'Menlo, monospace',
  display: 'Handlee, cursive',
  googleFonts: [
    'Comfortaa:200,300,400,500,600,700,800,900',
    'Handlee',
    'Noto Sans Display:100,200,300,400,500,600,700,800,900',
    'Playfair Display:100,200,300,400,500,600,700,800,900',
    'Tenor Sans: 300,400,500,600,700'
  ],
  customFamilies: ['Amelie', 'Corinthian Light', 'Futura'],
  customUrls: [
    'https://www.gonation.biz/fonts/amelie/amelie.css',
    'https://www.gonation.biz/fonts/corinthian-light/corinthian-light.css',
    'https://www.gonation.biz/fonts/futura/futura.css'
  ]
}
